
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



































































































.steps {
  background: $c-cultured;
}

.giga-fiber__testimonial {
  @include mq('l') {
    &::after {
      content: '';
      display: block;
      width: 15.4rem;
      height: 22.1rem;
      position: absolute;
      right: 4rem;
      bottom: -10rem;
      background-image: url('../../inc/svg/ui/testimonial-speedlines.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.giga-fiber__discover {
  padding: $spacing * 2.5 0;
  background-color: $c-cultured;

  ::v-deep {
    .product-slider {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}

::v-deep {
  .hero__slides__item__subtitle {
    @include mq('l') {
      margin-top: 3rem;
      font-size: 2.4rem;
    }
  }
  .hero-item__anchors {
    display: flex;
    margin-top: $spacing;

    @include mq($until: m) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }

  .hero-item__anchor {
    @extend %fw-bold;

    display: inline-flex;
    align-items: center;
    color: $c-blue-dark;
    line-height: 1;
    text-decoration: none;

    &:first-child {
      @include mq(m) {
        margin-right: $spacing * 1.5;
      }
    }

    .icon {
      width: 4rem;
      height: 5rem;
      margin-right: 10px;
      fill: $white;

      @include mq($until: m) {
        width: 3.4rem;
      }
    }

    @include mq(m) {
      margin-top: $spacing;
      margin-bottom: $spacing * 2.5;
    }

    @include mq(l) {
      margin-top: $spacing * 2;
      margin-bottom: 0;
    }
  }

  .card-small {
    background-color: #fff !important;
  }
}
