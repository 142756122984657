
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





























.cta-pack-composer {
  width: 40rem;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;

  @include mq('l') {
    width: 50rem;
    margin: 0;
  }

  @include mq('m') {
    width: 65rem;
  }
}

.cta-pack-composer__cta {
  padding-bottom: 0;
}

.cta-pack-composer__label {
  margin: 0 0 $spacing * 1.25;
  text-align: center;

  @include mq('l') {
    margin: 0 0 $spacing * 2.5;
    text-align: left;
  }
}

.cta-pack-composer__cta {
  width: 50rem;
  max-width: 100%;
}
