
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























































































.discover-offers {
  position: relative;
  padding: $spacing 0;
}

.discover-offers__title {
  margin-bottom: $spacing * 2;
  text-align: center;

  @include mq(l) {
    margin-bottom: $spacing * 5;
  }
}

.discover-offers__item {
  padding-right: 0;
  background: transparent;

  ::v-deep {
    .product-slider__slider {
      margin-bottom: 5rem;

      @include mq('xl') {
        margin-bottom: 5rem;
      }

      @include mq('xxl') {
        margin-bottom: 6rem;
      }

      @include mq('xxxl') {
        margin-bottom: 8rem;
      }
    }

    .product-card {
      height: 100%;
    }

    .carousel__scroller {
      --slide-gap: 2rem;

      &:not(.can-scroll) {
        // Align content if there's not enough to scroll
        justify-content: center;

        // Force item to take up all horizontal available space. Needed for justify-content to work
        flex-grow: 1;

        // Slide gap is higher when carousel is disabled
        /* stylelint-disable-next-line max-nesting-depth */
        @include mq('xl') {
          --slide-gap: 5rem;
        }

        // Slide gap is higher when carousel is disabled
        /* stylelint-disable-next-line max-nesting-depth */
        @include mq('xxl') {
          --slide-gap: 6rem;
        }

        // Slide gap is higher when carousel is disabled
        /* stylelint-disable-next-line max-nesting-depth */
        @include mq('xxxl') {
          --slide-gap: 8rem;
        }
      }

      @include mq('l') {
        --slide-gap: 4rem;
      }
    }
  }
}

.discover-offers__cta {
  margin: $spacing auto;

  ::v-deep .cta-pack-composer__label {
    text-align: center;
  }

  ::v-deep .cta-pack-composer__cta {
    width: 42rem;
    margin: auto;
  }

  ::v-deep .section-cta {
    background-color: white;
    &:hover {
      background-color: $c-anthracite-light;
    }
    &__content__title {
      @include fluid(
        font-size,
        (
          s: 18px,
          xxl: 25px,
        )
      );
    }
  }

  ::v-deep .cta-pack-composer__label {
    @extend %fw-medium;
    @include fluid(
      font-size,
      (
        s: 15px,
        xxl: 25px,
      )
    );
    @include fluid(
      line-height,
      (
        s: 20px,
        xxl: 35px,
      )
    );

    font-family: $ff-alt;
  }

  @include mq('l') {
    margin: $spacing * 3 auto;
  }
}

.offers-extra {
  // prettier-ignore
  @include fluid(font-size,(s: 16px,xxl: 24px,));

  // prettier-ignore
  @include fluid(line-height,(s: 24px,xxl: 36px,));

  text-align: center;

  ::v-deep a {
    font-weight: 500;
  }
}
