
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        







































































.giga-map-outer {
  @include bg-color;
}

.giga-map,
[class*='giga-map--'] {
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 4;

  @include mq(m) {
    display: flex;
  }
}

.giga-map__intro {
  @include mq(m) {
    flex-basis: col(4);
    margin-right: col(1);
  }
}

.giga-map__intro__title {
  @extend %fw-semi;
  margin-top: 0;
  padding-right: 5rem;
}

.giga-map__content {
  @include mq(m) {
    width: col(7);
  }
}

.giga-map__text {
  margin-bottom: $spacing * 2;
}

.giga-map__link {
  margin-top: $spacing;
}

.giga-map__insert {
  margin-top: $spacing * 4;
  padding: 3.5rem;
  position: relative;
  border: 1px solid $c-pink-medium;
  border-radius: 8px;
}

.giga-map__insert__label {
  position: absolute;
  top: 0;
  left: 2.5rem;
  transform: translateY(-50%);
  border-radius: 4px;
}

.giga-map__insert__title {
  margin-bottom: $spacing * 0.5;
}
.giga-map__insert__subtitle {
  margin-bottom: 2.5rem;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 2rem;
}

.giga-map__map {
  margin: 0 auto 6rem;
  max-width: 101.4rem;
  width: calc(100% - 4rem);
}

.giga-map__map__title {
  text-align: center;
}
