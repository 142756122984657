
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



















































































































































































.steps {
  @include bg-color;
}

.steps__inner {
  padding-top: $spacing * 2.5;
  padding-bottom: $spacing * 2.5;

  ::v-deep .mobile-evolution & {
    padding-top: 0;
  }

  @include mq(m) {
    padding-top: $spacing * 4.5;
    padding-bottom: $spacing * 4.5;
  }

  @include mq(l) {
    display: flex;
    flex-wrap: wrap;
  }

  @include mq(xl) {
    padding-top: $spacing * 6;
    padding-bottom: $spacing * 6;
  }
}

.steps__title {
  flex-basis: 100%;
  margin: 0 0 $spacing * 2;

  @include mq(m) {
    margin: 0 0 $spacing * 3;
  }

  @include mq(xl) {
    margin: 0 0 $spacing * 5;
  }
}

.steps__intro {
  margin-bottom: $spacing * 1.5;
  font-size: 1.8rem;
  line-height: 1.6;

  @include mq(l) {
    flex-basis: col(4);
    margin-right: col(1);
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

.steps__intro__title {
  margin-top: 0;
  margin-bottom: $spacing;
  font-size: 2.2rem;

  @include mq(m) {
    margin-bottom: $spacing * 1.5;
    font-size: 2.6rem;
  }

  @include mq(xl) {
    font-size: 3.2rem;
  }
}

.steps__intro__text {
  margin-top: 0;

  ::v-deep {
    a {
      color: $c-pink-medium;
    }
  }
}

.steps__intro__title + .steps__intro__text {
  margin-top: $spacing;
}

.steps__list {
  @include mq(l) {
    flex-basis: col(6);
  }
}

.steps__list__content {
  margin-bottom: $spacing * 3.5;
}

.steps__list__title {
  margin: 0 0 $spacing;
  font-size: 2rem;

  @include mq(m) {
    margin: 0 0 $spacing * 2;
    font-size: 2.4rem;
  }
}

.steps__list__items {
  margin: 0;
  padding: 0;
  counter-reset: steps;
}

.steps__list__item {
  margin-bottom: $spacing * 2.5;
  list-style-type: none;

  @include mq(m) {
    margin-bottom: $spacing * 3;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.steps__list__item__text,
.steps__list__item__title {
  margin: 0 0 $spacing * 0.5;

  @include mq(m) {
    margin: 0 0 $spacing;
  }
}

.steps__list__item__text {
  padding-left: 4rem;

  @include mq(m) {
    padding-left: 6rem;
  }
}

.steps__list__item__title {
  display: flex;
  align-items: center;

  &::before {
    content: counter(steps);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    margin-right: $spacing * 0.5;
    color: $c-pink-medium;
    font-size: 1.5rem;
    border: 0.3rem solid $c-pink-medium;
    counter-increment: steps;
    border-radius: 50%;
  }

  @include mq(m) {
    &::before {
      width: 4rem;
      height: 4rem;
      margin-right: $spacing;
      font-size: 2.4rem;
    }
  }

  span {
    @extend %fw-black;

    margin-left: 1rem;
    padding: 0.5rem $spacing * 0.5;
    color: $white;
    font-size: 1.1rem;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: $c-orange;
    border-radius: 4px;
  }
}

.steps__list__item__link {
  @extend %fw-bold;

  margin-left: 4rem;

  @include mq(m) {
    margin-left: 6rem;
  }
}

.steps__conditions {
  display: block;
  margin-top: $spacing;
  font-size: 1.1rem;
}

.section-row__content__new {
  position: relative;
  max-width: 44rem;
  margin-top: $spacing * 2;
  padding: 4rem;
  border: 1px solid $c-blue-dark;
  border-radius: 10px;

  @include mq(m) {
    width: 50rem;
  }
}

.section-row__content__new__label {
  position: absolute;
  top: 0;
  display: inline-block;
  margin-left: -$spacing / 2;
  padding: $spacing / 4 $spacing / 2;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.4rem;
  background-color: $c-pink-medium;
  border-radius: 3px;
  transform: translateY(-50%);
}

.section-row__content__new__title {
  margin: $spacing / 4 0;
}

.section-row__content__new__excerpt {
  margin: 0;
  font-size: 1.5rem;
}

.section-row__content__new__link {
  margin-top: $spacing;
}
