
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



















































































.map,
[class*='map--'] {
  position: relative;
  overflow: hidden;
  background-color: $c-white-dark;
}

.animated-map__inner {
  @include aspect-ratio(1014, 732);

  margin: 0 auto;
  display: block;
  width: 100%;

  img {
    @include image-fit(contain);
  }
}

.animated-map__pin {
  @include image-fit(contain);
  opacity: 0;
}
